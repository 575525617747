import { DatabaseProvider } from '@context/UserDatabase.context';
import UserTable from '@pages/users/components/UserTable';
import React from 'react';
import Search from './components/Search';

const UserPage = (): React.JSX.Element => {
  return (
    <div id="user-database" className="container mx-auto">
      <article className="prose mb-6">
        <h1>User Database</h1>
      </article>
      <Search />
      <UserTable />
    </div>
  );
};

const UserPageProvider = (): JSX.Element => {
  return (
    <DatabaseProvider>
      <UserPage />
    </DatabaseProvider>
  );
};

export default UserPageProvider;
