import { ReactComponent as IconLink } from '@assets/icon/open.svg';
import { Badge, Group, ScrollArea } from '@mantine/core';
import { MemberSearchResult } from '@models/Member.type';
import UserAvatar from '@pages/users/components/UserAvatar';
import { IconCircleFilled } from '@tabler/icons-react';
import _ from 'lodash';
import React from 'react';
import { TableColumn } from 'react-data-table-component';

export const columns: TableColumn<MemberSearchResult>[] = [
  {
    name: 'User ID',
    minWidth: '120px',
    cell: (row) => <UserAvatar frame={row.frame} picture={row.picture} userId={row.userId} alt={row.aliasName || row.name} />,
  },
  {
    name: 'ชาว ins',
    cell: (row) => row.donationPackage || '-',
  },
  {
    name: 'Verified',
    width: '80px',
    cell: (row) => {
      if (row.verified) {
        return <IconCircleFilled color="#5BBC69" size={14} />;
      }
      return <IconCircleFilled color="#E4E4E4" size={14} />;
    },
  },
  {
    name: 'หน้า Profile',
    minWidth: '10%',
    cell: (row) => (
      <a
        href={`${process.env.REACT_APP_NEWSITE_URL}/profile/${row.userId}`}
        target={row.aliasName || row.name}
        rel="noreferrer"
        className="text-secondary-500 underline"
      >
        <Group spacing={6}>
          {_.trim(row.aliasName || row.name)}
          <IconLink />
        </Group>
      </a>
    ),
  },
  {
    name: 'สมัครด้วย',
    cell: (row) => _.startCase(_.lowerCase(row.applicationChannel)),
  },
  {
    name: 'ข้อมูลการสมัคร',
    minWidth: '10%',
    cell: (row) => row.email || row.applicationInformation,
  },
  {
    name: 'ชื่อ นามสกุล',
    minWidth: '10%',
    cell: (row) => row.name,
  },
  {
    name: 'นามปากกา',
    minWidth: '140px',
    cell: (row) => row.aliasName || '-',
  },
  {
    name: 'ไลน์ที่ผูกบัญชี',
    cell: (row) => row.line || '-',
  },
  {
    name: 'เบอร์ติดต่อ',
    minWidth: '108px',
    cell: (row) => row.tel || '-',
  },
  {
    name: 'อีเมลติดต่อ',
    minWidth: '8%',
    cell: (row) => row.contactEmail || '-',
  },
  {
    name: 'ประเภทสมาชิก',
    width: '110px',
    cell: (row) => row.userType || '-',
  },
  {
    name: 'อาชีพ',
    cell: (row) => row.occupation || '-',
  },
  {
    name: 'ระดับชั้น',
    minWidth: '180px',
    cell: (row) => (
      <div className="flex snap-x flex-nowrap space-x-1.5 overflow-x-auto scroll-smooth">
        <ScrollArea type="always" scrollbarSize={8} styles={{ scrollbar: { cursor: 'pointer' } }}>
          <Group pr={16} spacing={4} noWrap>
            {row.levels?.map((level, i) => (
              <Badge key={`table-level-${i}`} variant="filled" size="lg" className="bg-secondary py-4 text-[12px] font-normal text-white">
                {level}
              </Badge>
            ))}
          </Group>
        </ScrollArea>
      </div>
    ),
  },
  {
    name: 'วิชาที่สอน',
    minWidth: '180px',
    cell: (row) => (
      <div className="flex snap-x flex-nowrap space-x-1.5 overflow-x-auto scroll-smooth">
        <ScrollArea type="always" scrollbarSize={8} styles={{ scrollbar: { cursor: 'pointer' } }}>
          <Group pr={16} spacing={4} noWrap>
            {row.subjects?.map((subject, i) => (
              <Badge key={`table-subject-${i}`} variant="filled" size="lg" className="bg-secondary py-4 text-[12px] font-normal text-white">
                {subject}
              </Badge>
            ))}
          </Group>
        </ScrollArea>
      </div>
    ),
  },
  {
    name: 'โรงเรียน',
    minWidth: '8%',
    cell: (row) => row.school || '-',
  },
  {
    name: 'จังหวัด',
    cell: (row) => row.province || '-',
  },
  {
    name: 'ยอดรวม donate',
    minWidth: '8%',
    cell: (row) => row.totalAmount || '-',
  },
];
