import { getLevels, getSubjects } from '@api/member/member.service';
import DonationPlanFilter from '@components/Filter/DonationPlanFilter';
import SchoolSelect from '@components/Filter/SchoolSeclect';
import NewSearch from '@components/UI/Search/NewSearch';
import { APPLICATION_CHANNELS, LevelItems, SubjectItems, USER_TYPE, VERIFY_STATUS } from '@constants/Category';
import OCCUPATIONS from '@constants/Occupations';
import provinces from '@constants/provinces';
import { useUserDatabaseDispatch, useUserDatabaseState } from '@context/UserDatabase.context';
import { Button, MultiSelect, Select } from '@mantine/core';
import { UserType } from '@models/Member.type';
import { ApplicationChannelType, SortType, UserDatabaseActionType } from '@models/UserDatabase.type';
import { cloneDeep, uniq } from 'lodash';
import React, { useEffect, useState } from 'react';

const Search = () => {
  const { sort, textSearch, lineName, email, applicationChannel, occupation, province, verified, userType, levels, subjects } =
    useUserDatabaseState();
  const dispatch = useUserDatabaseDispatch();
  const verifyStatusValue = (verified === null ? null : verified.toString()) as 'true' | 'false';
  const [levelData, setLevelData] = useState<string[]>([]);
  const [subjectData, setSubjectData] = useState<string[]>([]);

  useEffect(() => {
    getLevels().subscribe((levels) => {
      const levelItems = cloneDeep(LevelItems);
      setLevelData(uniq([...levelItems, ...levels]));
    });

    getSubjects().subscribe((subjects) => {
      const subjectItems = cloneDeep(SubjectItems);
      setSubjectData(uniq([...subjectItems, ...subjects]));
    });
  }, []);

  const handleChangeSort = (value: string): void => {
    dispatch({ type: UserDatabaseActionType.SET_SORT, payload: value as SortType });
  };

  const handleClickSearchIcon = (): void => {
    dispatch({ type: UserDatabaseActionType.CLICK_SEARCH, payload: true });
    dispatch({ type: UserDatabaseActionType.SET_PAGE, payload: 1 });
  };

  const handleChangeTextSearch = (value: string): void => {
    dispatch({ type: UserDatabaseActionType.SET_TEXT_SEARCH, payload: value });
  };

  const handleChangeLineName = (value: string): void => {
    dispatch({ type: UserDatabaseActionType.SET_LINE_NAME, payload: value });
    dispatch({ type: UserDatabaseActionType.SET_PAGE, payload: 1 });
  };

  const handleEmailSearch = (value: string): void => {
    dispatch({ type: UserDatabaseActionType.SET_EMAIL_SEARCH, payload: value });
    dispatch({ type: UserDatabaseActionType.SET_PAGE, payload: 1 });
  };

  const handleChangeApplicationChannel = (value: string): void => {
    dispatch({ type: UserDatabaseActionType.SET_APPLICATION_CHANNEL, payload: value as ApplicationChannelType });
    dispatch({ type: UserDatabaseActionType.CLICK_SEARCH, payload: true });
    dispatch({ type: UserDatabaseActionType.SET_PAGE, payload: 1 });
  };

  const handleChangeOccupation = (value: string[]): void => {
    dispatch({ type: UserDatabaseActionType.SET_OCCUPATION, payload: value.map((v) => v) });
    dispatch({ type: UserDatabaseActionType.CLICK_SEARCH, payload: true });
    dispatch({ type: UserDatabaseActionType.SET_PAGE, payload: 1 });
  };

  const handleChangeProvince = (value: string[]): void => {
    dispatch({ type: UserDatabaseActionType.SET_PROVINCE, payload: value.map((v) => v) });
    dispatch({ type: UserDatabaseActionType.CLICK_SEARCH, payload: true });
    dispatch({ type: UserDatabaseActionType.SET_PAGE, payload: 1 });
  };

  const handleClickClearSearch = (): void => {
    dispatch({ type: UserDatabaseActionType.CLEAR });
  };

  const handleChangeVerified = (value: 'true' | 'false' | null): void => {
    dispatch({ type: UserDatabaseActionType.SET_VERIFIED, payload: value !== null ? value === 'true' : null });
    dispatch({ type: UserDatabaseActionType.CLICK_SEARCH, payload: true });
    dispatch({ type: UserDatabaseActionType.SET_PAGE, payload: 1 });
  };

  const handleChangeUserType = (value: UserType): void => {
    dispatch({ type: UserDatabaseActionType.SET_USER_TYPE, payload: value });
    dispatch({ type: UserDatabaseActionType.CLICK_SEARCH, payload: true });
    dispatch({ type: UserDatabaseActionType.SET_PAGE, payload: 1 });
  };

  const handleChangeLevels = (value: string[]): void => {
    dispatch({ type: UserDatabaseActionType.SET_LEVELS, payload: value });
    dispatch({ type: UserDatabaseActionType.CLICK_SEARCH, payload: true });
    dispatch({ type: UserDatabaseActionType.SET_PAGE, payload: 1 });
  };

  const handleChangeSubjects = (value: string[]): void => {
    dispatch({ type: UserDatabaseActionType.SET_SUBJECT, payload: value });
    dispatch({ type: UserDatabaseActionType.CLICK_SEARCH, payload: true });
    dispatch({ type: UserDatabaseActionType.SET_PAGE, payload: 1 });
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        <h2 className="w-fit">เรียงลำดับจาก : </h2>
        <Select
          value={sort}
          onChange={handleChangeSort}
          data={[
            { value: 'USER_ID', label: 'เลขสมาชิก' },
            { value: 'TOP_DONATE', label: 'Top Donate' },
          ]}
        />
      </div>

      <div className="grid gap-2 md:grid-cols-2">
        <NewSearch
          placeholder="ค้นหาชื่อ นามปากกา อีเมลติดต่อ userid หรือเบอร์ติดต่อ"
          value={textSearch}
          onChange={(e) => handleChangeTextSearch(e.currentTarget.value)}
          onClickSearch={handleClickSearchIcon}
        />

        <div className="grid gap-2 md:grid-cols-2">
          <NewSearch
            placeholder="ค้นหาอีเมลที่ใช้สมัคร"
            value={email}
            onChange={(e) => handleEmailSearch(e.currentTarget.value)}
            onClickSearch={handleClickSearchIcon}
          />

          <NewSearch
            placeholder="ค้นหาไลน์"
            value={lineName}
            onChange={(e) => handleChangeLineName(e.currentTarget.value)}
            onClickSearch={handleClickSearchIcon}
          />
        </div>
      </div>

      <div className="grid gap-2 md:grid-cols-5">
        <DonationPlanFilter />

        <Select placeholder="Verified" clearable value={verifyStatusValue} data={VERIFY_STATUS} onChange={handleChangeVerified} />

        <Select
          placeholder="สมัครด้วย"
          clearable
          value={applicationChannel ? applicationChannel.toString() : ''}
          data={APPLICATION_CHANNELS}
          onChange={handleChangeApplicationChannel}
        />

        <Select placeholder="ประเภทสมาชิก" clearable value={userType} data={USER_TYPE} onChange={handleChangeUserType} />

        <MultiSelect placeholder="อาชีพ" clearable searchable value={occupation} data={OCCUPATIONS} onChange={handleChangeOccupation} />
      </div>

      <div className="grid gap-2 md:grid-cols-4">
        <MultiSelect
          placeholder="ระดับชั้น"
          clearable
          searchable
          nothingFound="ไม่พบระดับชั้นที่คุณค้นหา"
          value={levels}
          data={levelData}
          onChange={handleChangeLevels}
        />

        <MultiSelect
          placeholder="วิชาที่สอน"
          clearable
          searchable
          nothingFound="ไม่พบวิชาที่สอนที่คุณค้นหา"
          value={subjects}
          data={subjectData}
          onChange={handleChangeSubjects}
        />

        <SchoolSelect />

        <MultiSelect
          placeholder="จังหวัด"
          clearable
          value={province}
          searchable
          nothingFound="ไม่พบจังหวัดที่คุณค้นหา"
          data={provinces}
          onChange={handleChangeProvince}
        />
      </div>

      <div className="float-right">
        <Button className="float-right text-secondary-500" variant="subtle" onClick={handleClickClearSearch}>
          ล้างตัวกรอง
        </Button>
      </div>
    </div>
  );
};

export default Search;
