import {
  deleteIdea,
  exportIdeas,
  inskruSelectedIdea,
  permanentDeleteIdea,
  recommendIdea,
  restoreIdea,
  searchIdeas,
} from '@api/idea/idea.service';
import { IdeaInskruSelectedItems } from '@constants/IdeaDatabase';
import { useIdeaDatabaseDispatch, useIdeaDatabaseState } from '@context/IdeaDatabase.context';
import {
  Badge,
  Button,
  Container,
  Group,
  Image,
  Loader,
  Modal,
  Pagination as MantinePagination,
  Popover,
  Radio,
  ScrollArea,
  Space,
  Switch,
} from '@mantine/core';
import {
  IdeaDatabaseActionType,
  IdeaDatabaseSubmit,
  IdeaInskruSelectedReq,
  IdeaInskruSelectedType,
  IdeaResult,
  TypeOfIdea,
} from '@models/IdeaDatabase.type';
import { Pagination } from '@models/Pagination.type';
import UserAvatar from '@pages/ins/components/UserAvatar';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import DataTable, { TableColumn, TableStyles } from 'react-data-table-component';
import { finalize } from 'rxjs';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useIdeaDatabaseFunc } from './utils';

import DeleteIcon from '@assets/icon/deleteIcon.svg';
import RestoreButton from '@assets/icon/restoreButton.svg';
import EditTagIcon from '@assets/icon/editIcon.svg';
import FavIcon from '@assets/icon/favIcon.svg';
import GirlQuestionmark from '@assets/icon/GirlQuestionmark.svg';

import { IconLink } from '@tabler/icons-react';
import NumberFormat from '@utils/NumberFormat';
import IconDownload from '@components/Icon/IconDownload';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';

const MySwal = withReactContent(Swal);

const tableCustomStyles: TableStyles = {
  rows: {
    style: {
      minHeight: '50px',
    },
  },
  headCells: {
    style: {
      background: '#FBF8EF',
      fontSize: '15px',
    },
  },
};

const CustomLoader = (): React.JSX.Element => {
  return (
    <div className="py-24">
      <Loader size="xl" />
    </div>
  );
};

const IdeaTable = (): React.JSX.Element => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<IdeaInskruSelectedType>(IdeaInskruSelectedType.PARTICIPATION);
  const [deleteIdeaData, setDeleteIdeaData] = useState<IdeaResult | undefined>(undefined);
  const [popoverOpen, setPopoverOpen] = useState<string | null>(null);
  const [ideaData, setIdeaData] = useState<Pagination<IdeaResult>>({
    data: [],
    pages: 1,
    sizes: 10,
    totalData: 0,
    totalPages: 0,
  });

  const dispatch = useIdeaDatabaseDispatch();
  const filterState = useIdeaDatabaseState();
  const { formatDate } = useIdeaDatabaseFunc();

  const dataSubmit: IdeaDatabaseSubmit = {
    sizes: filterState.sizes,
    pages: filterState.pages,
    ideaTitle: filterState.ideaTitle,
    alias: filterState.alias,
    sort: filterState.sort,
    isRecommend: filterState.isRecommend,
    inskruSelected: filterState.inskruSelected,
    occupation: filterState.occupation,
    templateType: filterState.templateType,
    userTag: filterState.userTag,
    publishedDateStart: filterState.publishedDateStart,
    publishedDateEnd: filterState.publishedDateEnd,
    typeOfIdea: filterState.typeOfIdea,
  };

  useEffect(() => {
    if (filterState.isSearch) {
      dispatch({ type: IdeaDatabaseActionType.CLICK_SEARCH, payload: false });
      setFetching(true);
      searchIdeas(dataSubmit).subscribe((resp: Pagination<IdeaResult>) => {
        if (!resp?.data) {
          resp.data = [];
        }
        setIdeaData(resp);
        setFetching(false);
      });
    }
  }, [filterState.isSearch]);

  const handleChangePage = (page: number): void => {
    dispatch({ type: IdeaDatabaseActionType.SET_PAGE, payload: page });
    dispatch({ type: IdeaDatabaseActionType.CLICK_SEARCH, payload: true });
  };

  const CustomPagination = (): React.JSX.Element => {
    return (
      <Group sx={{ justifyContent: 'flex-end', padding: '1rem' }}>
        <MantinePagination
          withEdges
          page={filterState.pages}
          onChange={handleChangePage}
          radius="xl"
          size="lg"
          total={ideaData.totalPages}
          styles={() => ({
            item: {
              '&[data-active]': {
                backgroundColor: '#CBE7F6',
                color: 'black',
              },
            },
          })}
        />
      </Group>
    );
  };

  const handleToggleRecommendIdea = (ideaId: string): void => {
    recommendIdea(ideaId).subscribe();
  };

  const handleOpenModalConfirmDeleteIdea = (row: IdeaResult): void => {
    setOpenModalDelete(true);
    setDeleteIdeaData(row);
  };

  const handleOpenPopover = (ideaId: string): void => {
    setPopoverOpen(ideaId);
  };

  const handleInskruSelectedIdeaChange = (value: IdeaInskruSelectedType): void => {
    setSelectedOption(value);
  };
  const handleConfirmInskruSelectedIdea = (ideaId: string, selected: IdeaInskruSelectedType) => {
    const selectedType: IdeaInskruSelectedReq = {
      selectedType: selected,
    };
    inskruSelectedIdea(ideaId, selectedType)
      .pipe(
        finalize(() => {
          MySwal.fire({
            icon: 'success',
            title: 'ติด Inskru Selected สำเร็จแล้ว!',
            showConfirmButton: false,
            timer: 2000,
          });

          setPopoverOpen(null);
        })
      )
      .subscribe({
        next: () => {
          dispatch({ type: IdeaDatabaseActionType.CLICK_SEARCH, payload: true });
        },
        error: () => {
          MySwal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
            showConfirmButton: false,
            timer: 1500,
          });
        },
      });
  };

  const handleDownload = (data: IdeaDatabaseSubmit, setDownloadState: React.Dispatch<React.SetStateAction<boolean>>) => {
    setDownloadState(true);
    exportIdeas(data)
      .pipe(finalize(() => setDownloadState(false)))
      .subscribe({
        next: (resp) => {
          const file = new Blob([resp], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const now = dayjs().format('DD-MM-YYYY');
          saveAs(file, `idea-${now}.xlsx`);
        },
        error: () => {
          MySwal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
            showConfirmButton: false,
            timer: 1500,
          });
        },
      });
  };

  const handleRestoreIdea = (ideaId: string) => {
    restoreIdea(ideaId).subscribe({
      next: () => {
        MySwal.fire({
          icon: 'success',
          title: 'กู้คืนไอเดียสำเร็จ!',
          showConfirmButton: false,
          timer: 2000,
        });
        dispatch({ type: IdeaDatabaseActionType.CLICK_SEARCH, payload: true });
      },
      error: () => {
        MySwal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
          showConfirmButton: false,
          timer: 1500,
        });
      },
    });
  };

  const handlePermanentDeleteIdea = (ideaId: string) => {
    permanentDeleteIdea(ideaId)
      .pipe(
        finalize(() => {
          setDeleting(false);
          setOpenModalDelete(false);
        })
      )
      .subscribe({
        next: () => {
          MySwal.fire({
            icon: 'success',
            title: 'ลบไอเดียสำเร็จแล้ว!',
            showConfirmButton: false,
            timer: 2000,
          });

          dispatch({ type: IdeaDatabaseActionType.CLICK_SEARCH, payload: true });
        },
      });
  };
  const handleSoftDeleteIdea = (ideaId: string) => {
    deleteIdea(ideaId)
      .pipe(
        finalize(() => {
          setDeleting(false);
          setOpenModalDelete(false);
        })
      )
      .subscribe({
        next: () => {
          MySwal.fire({
            icon: 'success',
            title: 'ลบไอเดียสำเร็จแล้ว!',
            showConfirmButton: false,
            timer: 2000,
          });
          dispatch({ type: IdeaDatabaseActionType.CLICK_SEARCH, payload: true });
        },
      });
  };
  const handleClickDeleteIdea = (ideaId: string): void => {
    setDeleting(true);
    if (filterState.typeOfIdea === TypeOfIdea.PUBLISHED) {
      handleSoftDeleteIdea(ideaId);
    } else {
      handlePermanentDeleteIdea(ideaId);
    }
  };
  const ideaColumns: TableColumn<IdeaResult>[] = [
    {
      name: '',
      width: '150px',
      cell: (row) => (
        <div className="flex gap-4">
          {filterState?.typeOfIdea === TypeOfIdea.PUBLISHED ? (
            <>
              <a
                href={`/ideas/edit/${row?.ideaId}`}
                title={`idea-${row.ideaId}`}
                target="_blank"
                rel="noreferrer"
                className="text-secondary-500 underline"
              >
                <Image className="cursor-pointer" src={EditTagIcon} alt="EditTagIcon" width={32} height={24} />
              </a>
              <Image
                onClick={() => handleOpenModalConfirmDeleteIdea(row)}
                className="cursor-pointer"
                src={DeleteIcon}
                alt="EditTagIcon"
                width={32}
                height={24}
              />
              <div onClick={() => handleOpenPopover(row.ideaId)}>
                <Popover
                  withinPortal
                  width={280}
                  position="right"
                  radius={16}
                  withArrow
                  shadow="md"
                  transition="pop"
                  opened={popoverOpen === row.ideaId}
                  onClose={() => setPopoverOpen(null)}
                >
                  <Popover.Target>
                    <Image className="cursor-pointer" src={FavIcon} alt="FavIcon" width={32} height={24} />
                  </Popover.Target>
                  <Popover.Dropdown>
                    <Radio.Group value={selectedOption} onChange={handleInskruSelectedIdeaChange}>
                      {IdeaInskruSelectedItems.map((item, index) => (
                        <Radio
                          className="flex h-4 w-full items-center rounded-lg border border-outline px-4 py-6 font-medium"
                          key={index}
                          label={item.label}
                          value={item.value}
                        />
                      ))}
                    </Radio.Group>
                    <Button
                      className="float-right mt-4 flex rounded-full bg-primary px-4 text-lg font-normal text-black no-underline"
                      onClick={() => handleConfirmInskruSelectedIdea(row.ideaId, selectedOption)}
                    >
                      ยืนยัน
                    </Button>
                  </Popover.Dropdown>
                </Popover>
              </div>
            </>
          ) : (
            <>
              <Image
                onClick={() => handleRestoreIdea(row.ideaId)}
                className="cursor-pointer"
                src={RestoreButton}
                alt="restore button"
                width={32}
                height={24}
              />
              <Image
                onClick={() => handleOpenModalConfirmDeleteIdea(row)}
                className="cursor-pointer"
                src={DeleteIcon}
                alt="EditTagIcon"
                width={32}
                height={24}
              />
            </>
          )}
        </div>
      ),
    },
    {
      name: 'Published',
      width: '105px',
      cell: (row) => (row?.publishedTime ? formatDate(row?.publishedTime) : '-'),
    },
    {
      name: 'แนะนำ',
      width: '80px',
      cell: (row) =>
        filterState?.typeOfIdea === TypeOfIdea.DELETED ? (
          '-'
        ) : (
          <>
            <Switch
              defaultChecked={row?.isHighlight}
              onChange={() => {
                handleToggleRecommendIdea(row?.ideaId);
              }}
            />
          </>
        ),
    },
    {
      name: 'insKru Selected',
      width: '144px',
      cell: (row) => row?.inskruSelected || '-',
    },
    {
      name: 'ชื่อไอเดีย',
      width: '200px',
      cell: (row) => (
        <a href={`${row?.linkIdea}`} target="_blank" rel="noreferrer" className="text-secondary-500 underline">
          <Group spacing={6}>
            {_.trim(row?.title)}
            <IconLink />
          </Group>
        </a>
      ),
    },
    {
      name: 'นามปากกา',
      width: '120px',
      cell: (row) => (row?.alias ? row?.alias : row?.ownerName),
    },
    {
      name: 'Userid',
      width: '120px',
      cell: (row) => <UserAvatar picture={row?.picture} userId={row?.ownerId} alt={row?.name} />,
    },
    {
      name: 'User Tag',
      width: '220px',
      cell: (row) => (
        <div className="mr-1 flex snap-x flex-nowrap items-center space-x-1.5 overflow-x-auto scroll-smooth">
          <ScrollArea type="always" scrollbarSize={8} styles={{ scrollbar: { cursor: 'pointer' } }}>
            <Group pr={16} spacing={4} noWrap>
              {row?.tags?.map((item, idx) => {
                return (
                  <React.Fragment key={`ideaTags-${idx}`}>
                    <Badge variant="filled" size="lg" className="bg-secondary py-4 text-[12px] font-normal text-white">
                      {item}
                    </Badge>
                  </React.Fragment>
                );
              })}
            </Group>
            <Space h={8} />
          </ScrollArea>
        </div>
      ),
    },

    {
      name: 'Viewed',
      width: '120px',
      cell: (row) => NumberFormat(row?.viewCount) || '-',
    },
    {
      name: 'Comment',
      width: '120px',
      cell: (row) => NumberFormat(row?.commentCount) || '-',
    },
    {
      name: 'Download',
      width: '120px',

      cell: (row) => NumberFormat(row?.totalDownload) || '-',
    },
    {
      name: 'Save',
      width: '120px',
      cell: (row) => NumberFormat(row?.saveCount) || '-',
    },
    {
      name: 'inspire',
      width: '120px',
      cell: (row) => NumberFormat(row?.inspireCount) || '0',
    },
    {
      name: 'flower',
      width: '120px',
      cell: (row) => NumberFormat(row?.flowerCount) || '0',
    },
    {
      name: 'Template',
      width: '120px',
      cell: (row) => row?.templateType || '-',
    },
    {
      name: 'Occupation',
      width: '120px',
      cell: (row) => row?.occupation || '-',
    },
    {
      name: 'Updated',
      width: '120px',
      cell: (row) => formatDate(row?.updatedTime) || '-',
    },
  ];

  return (
    <div>
      <div className="mt-6 overflow-x-auto rounded-xl border-2">
        <div className="flex items-center justify-between border-b-2 p-6 text-disabled2">
          <span>จำนวน {ideaData?.totalData.toLocaleString()} ผลลัพธ์</span>
          <Button
            disabled={filterState?.typeOfIdea === TypeOfIdea.DELETED}
            key="download-button"
            leftIcon={<IconDownload />}
            variant="outline"
            color="gray"
            loading={downloading}
            loaderProps={{ type: 'dots' }}
            className="rounded-full px-4 text-black hover:bg-slate-100"
            onClick={() => handleDownload(filterState, setDownloading)}
          >
            xlsx
          </Button>
        </div>

        <Container sx={{ maxWidth: '100%' }} px={0}>
          <DataTable<IdeaResult>
            className="scrollbar-custom"
            pagination
            progressPending={fetching}
            progressComponent={<CustomLoader />}
            customStyles={tableCustomStyles}
            paginationComponent={CustomPagination}
            paginationRowsPerPageOptions={[10]}
            columns={ideaColumns}
            data={ideaData.data}
            noDataComponent={
              <div className="flex flex-col items-center py-4 text-center font-light text-disabled2">
                <Image src={GirlQuestionmark} alt="not found" width={100} height={100}></Image>
                <p className="mt-4">หาไม่เจอง่ะ? ลอง</p>
                <ul className="w-fit list-inside list-disc">
                  <li>ค้นหาด้วยช่องอื่นๆ หรือถามข้อมูลจากครูเพิ่ม</li>
                  <li>เช็คว่าล้างการค้นหาช่องอื่นๆ หมดแล้วรึยัง</li>
                </ul>
              </div>
            }
          />
        </Container>

        <Modal
          centered
          opened={openModalDelete}
          radius="md"
          onClose={() => setOpenModalDelete(false)}
          title={<h1 className="text-2xl text-error">ลบไอเดีย?</h1>}
          styles={{ title: { display: 'flex', justifyContent: 'center', width: '100%' } }}
        >
          <div className="flex flex-col items-center">ต้องการลบไอเดีย : {deleteIdeaData?.title}</div>
          <div className="mt-4 flex justify-end">
            <Button
              variant="outline"
              color="red"
              className="mr-4 rounded-full px-4 text-lg font-normal text-black no-underline hover:bg-red-100"
              onClick={() => handleClickDeleteIdea(deleteIdeaData?.ideaId as string)}
              loading={deleting}
              loaderProps={{ type: 'dots' }}
            >
              ลบไอเดีย
            </Button>
            <Button
              variant="outline"
              className="rounded-full px-4 text-lg font-normal text-black no-underline"
              onClick={() => setOpenModalDelete(false)}
            >
              ไม่
            </Button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default IdeaTable;
