import { InsDatabaseProvider } from '@context/InsUserDatabase.context';
import React from 'react';
import { Link } from 'react-router-dom';
import InsTable from './components/InsTable';
import SearchIns from './components/SearchIns';
import { Button } from '@mantine/core';

const InsUserPage = (): React.JSX.Element => {
  return (
    <div id="ins-database" className="container mx-auto">
      <article className="prose mb-10 flex items-center">
        <h1 className="mb-0 mr-6">ชาวอินส์ Database</h1>
        <Link to="/ins/adds">
          <Button className="rounded-full bg-primary px-4 text-xl font-normal text-black no-underline hover:bg-primary-300">
            เพิ่มชาวอินส์
          </Button>
        </Link>
      </article>
      <SearchIns />
      <InsTable />
    </div>
  );
};

const InsUserPageProvider = (): JSX.Element => {
  return (
    <InsDatabaseProvider>
      <InsUserPage />
    </InsDatabaseProvider>
  );
};

export default InsUserPageProvider;
