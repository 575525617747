import UserDatabaseFilter, { UserDatabaseDispatch, UserDatabaseAction, UserDatabaseActionType } from '@models/UserDatabase.type';
import React, { useReducer } from 'react';

interface UserDatabaseProviderProps {
  children: React.ReactNode;
}

const UserDatabaseContext = React.createContext<UserDatabaseFilter | undefined>(undefined);
const UserDatabaseDispatchContext = React.createContext<UserDatabaseDispatch | undefined>(undefined);

const reducer = (state: UserDatabaseFilter, { type, payload }: UserDatabaseAction): UserDatabaseFilter => {
  switch (type) {
    case UserDatabaseActionType.SET_TEXT_SEARCH: {
      return { ...state, textSearch: payload };
    }

    case UserDatabaseActionType.SET_LINE_NAME: {
      return { ...state, lineName: payload };
    }

    case UserDatabaseActionType.SET_EMAIL_SEARCH: {
      return { ...state, email: payload };
    }

    case UserDatabaseActionType.SET_SORT: {
      return { ...state, sort: payload };
    }

    case UserDatabaseActionType.SET_DONATION_PACKAGE: {
      return { ...state, donationPackage: payload };
    }

    case UserDatabaseActionType.SET_APPLICATION_CHANNEL: {
      return { ...state, applicationChannel: payload };
    }

    case UserDatabaseActionType.SET_SCHOOL: {
      return { ...state, school: payload };
    }

    case UserDatabaseActionType.SET_PROVINCE: {
      return { ...state, province: payload };
    }

    case UserDatabaseActionType.SET_OCCUPATION: {
      return { ...state, occupation: payload };
    }

    case UserDatabaseActionType.SET_PAGE: {
      return { ...state, pages: payload };
    }

    case UserDatabaseActionType.SET_VERIFIED: {
      return { ...state, verified: payload };
    }

    case UserDatabaseActionType.SET_USER_TYPE: {
      return { ...state, userType: payload };
    }

    case UserDatabaseActionType.SET_LEVELS: {
      return { ...state, levels: payload };
    }

    case UserDatabaseActionType.SET_SUBJECT: {
      return { ...state, subjects: payload };
    }

    case UserDatabaseActionType.CLICK_SEARCH: {
      return { ...state, isSearch: payload };
    }

    case UserDatabaseActionType.CLEAR: {
      return new UserDatabaseFilter();
    }

    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

const DatabaseProvider: React.FC<UserDatabaseProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, new UserDatabaseFilter());

  return (
    <UserDatabaseContext.Provider value={state}>
      <UserDatabaseDispatchContext.Provider value={dispatch}>{children}</UserDatabaseDispatchContext.Provider>
    </UserDatabaseContext.Provider>
  );
};

const useUserDatabaseState = (): UserDatabaseFilter => {
  const context = React.useContext(UserDatabaseContext);
  if (context === undefined) {
    throw new Error('useUserDatabaseState must be used within a UserDatabaseProvider');
  }
  return context;
};

const useUserDatabaseDispatch = (): UserDatabaseDispatch => {
  const context = React.useContext(UserDatabaseDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDatabaseDispatch must be used within a UserDatabaseProvider');
  }
  return context;
};

export { DatabaseProvider, useUserDatabaseState, useUserDatabaseDispatch };
