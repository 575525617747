import { exportMember, searchMember } from '@api/member/member.service';
import GirlQuestionmark from '@assets/icon/GirlQuestionmark.svg';
import { useUserDatabaseDispatch, useUserDatabaseState } from '@context/UserDatabase.context';
import { Container, Group, Image, Pagination as MantinePagination, Loader, Button } from '@mantine/core';
import { MemberSearchResult } from '@models/Member.type';
import { Pagination } from '@models/Pagination.type';
import UserDatabaseFilter, { UserDatabaseActionType } from '@models/UserDatabase.type';
import React, { useEffect, useState } from 'react';
import DataTable, { TableStyles } from 'react-data-table-component';
import { columns } from './Columns';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
import { finalize } from 'rxjs';
import IconDownload from '@components/Icon/IconDownload';

const tableCustomStyles: TableStyles = {
  rows: {
    style: {
      minHeight: '50px',
    },
  },
  headCells: {
    style: {
      background: '#FBF8EF',
    },
  },
  cells: {
    style: {},
  },
};

const CustomLoader = (): React.JSX.Element => {
  return (
    <div className="py-24">
      <Loader size="xl" />
    </div>
  );
};

const onClick = (data: UserDatabaseFilter, setDownloadState: React.Dispatch<React.SetStateAction<boolean>>) => {
  setDownloadState(true);
  exportMember(data)
    .pipe(finalize(() => setDownloadState(false)))
    .subscribe({
      next: (resp) => {
        const file = new Blob([resp], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const now = dayjs().format('DD-MM-YYYY');
        saveAs(file, `member-${now}.xlsx`);
      },
      error: () => {},
    });
};

export default function UserTable(): React.JSX.Element {
  const filterState = useUserDatabaseState();
  const dispatch = useUserDatabaseDispatch();
  const [fetching, setFetching] = useState<boolean>(false);
  const [isDownload, setDownload] = useState<boolean>(false);
  const [memberData, setMemberData] = useState<Pagination<MemberSearchResult>>({
    data: [],
    pages: 1,
    sizes: 10,
    totalData: 0,
    totalPages: 0,
  });

  useEffect(() => {
    if (filterState.isSearch) {
      dispatch({ type: UserDatabaseActionType.CLICK_SEARCH, payload: false });
      setFetching(true);
      searchMember(filterState).subscribe((resp: Pagination<MemberSearchResult>) => {
        if (!resp?.data) {
          resp.data = [];
        }

        setMemberData(resp);
        setFetching(false);
      });
    }
  }, [filterState.isSearch]);

  const handleChangePage = (page: number): void => {
    dispatch({ type: UserDatabaseActionType.SET_PAGE, payload: page });
    dispatch({ type: UserDatabaseActionType.CLICK_SEARCH, payload: true });
  };

  const CustomPagination = (): React.JSX.Element => {
    return (
      <Group sx={{ justifyContent: 'flex-end', padding: '1rem' }}>
        <MantinePagination
          withEdges
          page={filterState.pages}
          onChange={handleChangePage}
          radius="xl"
          size="lg"
          total={memberData.totalPages}
          styles={() => ({
            item: {
              '&[data-active]': {
                backgroundColor: '#CBE7F6',
                color: 'black',
              },
            },
          })}
        />
      </Group>
    );
  };

  return (
    <div className="mt-6 overflow-x-auto rounded-xl border-2">
      <div className="flex items-center justify-between border-b-2 p-6 text-disabled2">
        <span>จำนวน {memberData?.totalData.toLocaleString()} ผู้ใช้</span>
        <Button
          key="download-button"
          leftIcon={<IconDownload />}
          variant="outline"
          color="gray"
          loading={isDownload}
          loaderProps={{ type: 'dots' }}
          className="rounded-full px-4 text-black hover:bg-slate-100"
          onClick={() => onClick(filterState, setDownload)}
        >
          xlsx
        </Button>
      </div>
      <Container sx={{ maxWidth: '100%' }} px={0}>
        <DataTable<MemberSearchResult>
          className="scrollbar-custom"
          pagination
          progressPending={fetching}
          progressComponent={<CustomLoader />}
          customStyles={tableCustomStyles}
          paginationComponent={CustomPagination}
          paginationRowsPerPageOptions={[10]}
          columns={columns}
          data={memberData.data}
          noDataComponent={
            <div className="flex flex-col items-center py-4 text-center font-light text-disabled2">
              <Image src={GirlQuestionmark} alt="not found" width={100} height={100}></Image>
              <p className="mt-4">หาไม่เจอง่ะ? ลอง</p>
              <ul className="w-fit list-inside list-disc">
                <li>ค้นหาด้วยช่องอื่นๆ หรือถามข้อมูลจากครูเพิ่ม</li>
                <li>เช็คว่าล้างการค้นหาช่องอื่นๆ หมดแล้วรึยัง</li>
              </ul>
            </div>
          }
        />
      </Container>
    </div>
  );
}
