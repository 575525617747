import { SelectItem } from '@mantine/core';
import { ApplicationChannelType } from '@models/UserDatabase.type';

export const APPLICATION_CHANNELS: SelectItem[] = [
  {
    value: ApplicationChannelType.FACEBOOK,
    label: 'Facebook',
  },
  {
    value: ApplicationChannelType.EMAIL,
    label: 'Email',
  },
];

export const VERIFY_STATUS: string[] = ['true', 'false'];
export const USER_TYPE: string[] = ['teacher', 'guest'];

export const LevelItems: string[] = [
  'เตรียมอนุบาล',
  'อนุบาล',
  'ประถมต้น',
  'ประถมปลาย',
  'มัธยมต้น',
  'มัธยมปลาย',
  'ปวช.',
  'ปวส.',
  'กศน.',
  'อุดมศึกษา',
];

export const SubjectItems: string[] = [
  'ภาษาไทย',
  'ภาษาอังกฤษ',
  'ภาษาต่างประเทศ',
  'คณิตศาสตร์',
  'สังคมศึกษา',
  'วิทยาศาสตร์',
  'ฟิสิกส์',
  'เคมี',
  'ชีววิทยา',
  'ดนตรี',
  'นาฏศิลป์',
  'ศิลปะ',
  'สุขศึกษาและพลศึกษา',
  'วิทยาการคำนวณ',
  'แนะแนว',
  'การงานอาชีพ',
  'การออกแบบและเทคโนโลยี',
  'กิจกรรมเสริม',
];
